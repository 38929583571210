import React from "react"
//import { Link } from "gatsby"
import { MdFiberManualRecord } from "react-icons/md"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Project = ({ intl }) => (
  <Layout>
    <SEO title="Diego Achury - Proyectos" />
    <div className="container">
      <section className="projects blue-fluor">
        <div className="cont-pro">
          <div className="copy">
            <p className="sub">
              <FormattedMessage id="projects.subTitle" />
            </p>
            <p className="title">
              <FormattedMessage id="projects.title" />
            </p>
            <div className="cont-btn">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=573007983173&text=Hola%20me%20gustaria%20tu%20ayuda%20en%20un%20proyecto"
                className="btn bt-contact"
              >
                <FormattedMessage id="projects.btn" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="proj">
        <div className="cont-row">
          <div className="cont-8col colm8_sm_lg">
            <div className="cont-project">
              <div className="copy">
                <p className="sub">SITE</p>
                <p className="title">
                  <FormattedMessage id="listProyect.four.title" />
                </p>
                <p className="desc">
                  <FormattedMessage id="listProyect.four.desc" />
                </p>
                <div className="stats">
                  <div className="box-stats">
                    <p className="title">1k</p>
                    <p className="desc">VIEW</p>
                  </div>
                  <div className="box-stats">
                    <div className="icons-cont" style={{ color: "#75FC7D" }}>
                      <MdFiberManualRecord />
                    </div>
                    <p className="desc">ACTIVO</p>
                  </div>
                </div>
                <div className="cont-btn">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn bt-connect md-btn"
                    href="https://www.lasalle.edu.co/UniversidaddeLASALLE/"
                  >
                    CLICK ACCION!
                  </a>

                  {/* <button className="btn bt-connect md-btn">Button</button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="cont-4col colm4_sm_lg ">
            <div className="cont-img">
              <img src={"/la_salle.png"} alt="" />
            </div>
          </div>
        </div>
        <div className="cont-row">
          <div className="cont-8col colm8_sm_lg">
            <div className="cont-project">
              <div className="copy">
                <p className="sub">WEBAPP - BETA</p>
                <p className="title">
                  <FormattedMessage id="listProyect.one.title" />
                </p>
                <p className="desc">
                  <FormattedMessage id="listProyect.one.desc" />
                </p>
                <div className="stats">
                  <div className="box-stats">
                    <p className="title">1k</p>
                    <p className="desc">VIEW</p>
                  </div>
                  <div className="box-stats">
                    <div className="icons-cont" style={{ color: "#75FC7D" }}>
                      <MdFiberManualRecord />
                    </div>
                    <p className="desc">ACTIVO</p>
                  </div>
                </div>
                <div className="cont-btn">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn bt-connect md-btn"
                    href="http://schoolbooks.lubumas.com/"
                  >
                    CLICK ACCION!
                  </a>

                  {/* <button className="btn bt-connect md-btn">Button</button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="cont-4col colm4_sm_lg ">
            <div className="cont-img">
              <img src={"/art-web03@2x.svg"} alt="" />
            </div>
          </div>
        </div>
        <div className="cont-row">
          <div className="cont-8col colm8_sm_lg ">
            <div className="cont-project">
              <div className="copy">
                <p className="sub">SITE WEB</p>
                <p className="title">
                  <FormattedMessage id="listProyect.two.title" />
                </p>
                <p className="desc">
                  <FormattedMessage id="listProyect.two.desc" />
                </p>
                <div className="stats">
                  <div className="box-stats">
                    <p className="title">7k</p>
                    <p className="desc">VIEW</p>
                  </div>
                  <div className="box-stats">
                    <div className="icons-cont" style={{ color: "#FD0052" }}>
                      <MdFiberManualRecord />
                    </div>
                    <p className="desc">OFFLINE</p>
                  </div>
                </div>
                <div className="cont-btn">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn bt-connect md-btn"
                    href="https://www.awwwards.com/sites/chivas-regal-us"
                  >
                    CLICK ACCION!
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cont-4col colm4_sm_lg ">
            <div className="cont-img">
              <img src={"/art-web01@2x.svg"} alt="" />
            </div>
          </div>
        </div>
        <div className="cont-row">
          <div className="cont-8col colm8_sm_lg ">
            <div className="cont-project">
              <div className="copy">
                <p className="sub">INTRANET</p>
                <p className="title">
                  <FormattedMessage id="listProyect.three.title" />
                </p>
                <p className="desc">
                  <FormattedMessage id="listProyect.three.desc" />
                </p>
                <div className="stats">
                  <div className="box-stats">
                    <p className="title">75k</p>
                    <p className="desc">VIEW</p>
                  </div>
                  <div className="box-stats">
                    <div className="icons-cont" style={{ color: "#75FC7D" }}>
                      <MdFiberManualRecord />
                    </div>
                    <p className="desc">ACTIVO</p>
                  </div>
                </div>
                <div className="cont-btn">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn bt-connect md-btn"
                    href="https://www.dnp.gov.co"
                  >
                    CLICK ACCION!
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cont-4col colm4_sm_lg ">
            <div className="cont-img">
              <img src={"/art-web02@2x.svg"} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default injectIntl(Project)
